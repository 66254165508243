export const priceData = [
  {
    name: "Летний бассейн",
    title: "Выходные дни",
    li: "• Взрослые (с 10 лет) :<span>250р</span> <span>800р</span>/день ",
    li2: "• Дети (с 5 до 10 лет): <span>200р</span>/час; <span>600р</span>/день",
    li2Span: "",
    li2Span2: "",
    title2: "Будние дни",
    li3: "• Взрослые (с 10 лет): <span>250р</span>/час; <span>700р</span>/день",
    li4: "• Дети (с 5 до 10 лет): <span>150р</span>/час; <span>450р</span>/день",
    footerClass: "footerClass",
    li5: "• Беседка малая <span>1000р</span>",
    li6: "• Беседка большая <span>2000р</span>",
    li7: "• Шатер <span>500р</span>",
    link: "/waterpool",
    id: "One",
  },
  {
    name: "Гостиничные номера",
    li: "• <span>1800р</span>/сутки",
    link: "/rooms",
    id: "Two",
  },
  {
    name: "Сауна №1",
    li: "• цена рассчитана на 10 человек: <span>1200р</span>/час;",
    li2: "• Если количество человек превышает вместимость зала, то дополнительное место оплачивается из расчета <span>100р</span>/час",
    link: "/sauna",
    id: "Three",
  },
  {
    name: "Русская баня на дровах",
    orangeClass: "orangeText",
    orangeText:
      "При посещении бани более чем на 5 часов, парение в Сибирском чане в подарок!",
    li: "• Русская баня <span>1800р</span>/час",
    li2: "• Веники (дубовый, березовый, эвкалиптовый) <span>200-250р</span>",
    li3: "• Самовар на дровах <span>250р</span>",
    li4: "• Сибирский чан <span>1500р</span>",
    link: "/russianSauna",
    id: "Four",
  },
  {
    name: "Хамам",
    li: "• цена рассчитана на 10 человек: <span>1200р</span>/час",
    li2: "• Если количество человек превышает вместимость зала, то дополнительное место оплачивается из расчета <span>100р</span>/час",
    link: "/hamam",
    id: "Five",
  },
  {
    name: "Хамам 2",
    li: "• <span>2500р</span>/час будние дни",
    li2: "• <span>3000р</span>/час выходные и праздничные дни",
    link: "hamam2",
    id: "Six",
  },
  {
    name: "Сауна №2",
    li: "• цена рассчитана на 10 человек: <span>1500р</span>/час",
    li2: "• Если количество человек превышает вместимость зала, то дополнительное место оплачивается из расчета <span>100р</span>/час",
    link: "sauna2",
    id: "Seven",
  },
  {
    name: "Номер с финской парной и джакузи",
    li: "• цена рассчитана на 5 человек: <span>900р</span>/час",
    link: "finnishRoom",
    id: "Eight",
  },
];
