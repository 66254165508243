import starIcon from "../images/starOrange.svg";
export const star = [
  {
    starIcon,
    alt: 'звезда'
  },
  {
    starIcon,
    alt: 'звезда'
  },
  {
    starIcon,
    alt: 'звезда'
  },
  {
    starIcon,
    alt: 'звезда'
  },
  {
    starIcon,
    alt: 'звезда'
  }
]