import img from '../images/gallery/1.png'
import img2 from '../images/gallery/2.png'
import img3 from '../images/gallery/3.png'
import img4 from '../images/gallery/4.png'
import img5 from '../images/gallery/5.png'
import img6 from '../images/gallery/6.png'
import img7  from '../images/gallery/7.png'
import img8 from '../images/gallery/8.png'

export const gallaryData = [
  {
    img: img8,
    id: 'one'
  },
  {
    img: img2,
    id: 'two'
  },
  {
    img: img,
    id: 'three'
  },
  {
    img: img3,
    id:  'four'
  },
  {
    img: img4,
    id: 'five'
  },
  {
    img: img5,
    id: 'six'
  },
  {
    img: img6,
    id: 'seven'
  },
  {
    img: img7,
    id: 'eight'
  }
]